@-moz-document url-prefix() {
  .card-component > .htColumnHeaders > .ht_master > .wtHolder {
    overflow-y: scroll !important;
  }
}
html, body {
  background-color: #f1f1f3;
  margin: 0;
  height: 100%;
}
body,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.k-drawer-container {
  height: calc(100vh - 80px);
}
.k-drawer-container .k-drawer {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-right-width: 0 !important;
}
.k-drawer-container.k-drawer-push .k-drawer {
  position: sticky;
  top: 0px;
}
.k-drawer-container .k-drawer-item {
  user-select: none;
}
.k-drawer-content > div {
  text-align: center;
  max-height: calc(100vh - 80px);
  max-width: 100vw;
}
.card-container {
  display: inline-block;
  background-color: #fff;
  padding: 32px;
  text-align: center;
  margin: 16px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.card-container.grid {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 16px;
  /* overflow: auto; */
}
.card-container.column {
  display: inline-block;
}
.card-container .card-buttons {
  text-align: right;
  grid-column: 0.6666666667;
  grid-row: 1;
}
.card-container .card-buttons > .k-button-rectangle {
  min-width: 105px;
}
.card-container .card-ranges .k-daterangepicker .k-textbox-container,
.card-container .card-ranges .k-daterangepicker .k-floating-label-container {
  padding-top: 0;
  min-width: 105px;
}
.card-container .card-ranges .k-daterangepicker .k-label {
  display: none;
}
.card-container .card-component {
  grid-column: -1;
  grid-column-start: 1;
  grid-column-end: -1;
  overflow-x: auto;
}
.card-container.two-grid {
  grid-template-columns: repeat(2, 1fr);
}
.card-container.two-grid .k-button {
  height: 60px;
  width: 200px;
}
.card-container.title-card {
  display: inline-block;
  margin: 2em auto 1em auto;
  padding: 15px 30px;
}
.card-container .card-title {
  grid-column: 0.5;
  grid-row: 1;
}
.card-container.small-card {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.header {
  background-color: #1555c4;
  height: 80px;
  top: 0;
  z-index: 200;
}
.header .nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #fff;
}
.header .menu-button {
  position: relative;
  flex: 0 0 75px;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.header .k-i-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em 0 0 -0.5em;
}
.header .title {
  display: flex;
  margin-left: 1em;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
}
.header .title h1 {
  cursor: pointer;
}
.header .logo {
  position: absolute;
  font-size: 2.5em;
  user-select: none;
  left: 50%;
  transform: translate(-50%, 0);
}
.header .user {
  flex-grow: 1;
  padding-right: 24px;
  user-select: none;
}
.user-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header .settings {
  display: none;
}
.button-grid {
  width: 50%;
}
.page-title {
  color: #424242;
  user-select: none;
}
.menu-dropdown {
  position: relative;
  display: block;
}
.menu-dropdown-content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100%;
}
.menu-dropdown:hover .menu-dropdown-content {
  display: block;
}
.nav-menu {
  opacity: 0;
  transition: all 100ms ease 0s;
}
.handsontable.htColumnHeaders {
  display: inline-block;
}
.handsontable td {
  word-wrap: break-word;
}
.handsontable th > div {
  text-align: left;
}
.handsontable .invalid {
  background-color: #a8baff;
}
.handsontable .unpermitted {
  background-color: #e4e4e4;
}
.handsontable .inactive {
  text-decoration: line-through;
}
.handsontable .changed {
  font-weight: bold;
}
.handsontable.listbox {
  overflow: visible !important;
}
.handsontable.listbox .htCore {
  width: auto;
}
.handsontable.listbox .wtHolder {
  width: auto !important;
  position: absolute !important;
  overflow-x: visible;
  overflow-y: scroll;
}
.handsontable.listbox .wtHider {
  width: auto !important;
}
.handsontable.listbox .wtSpreader {
  width: auto;
}
.handsontable.listbox > .handsontable {
  overflow: visible;
}
.handsontable.listbox td {
  text-align: left;
}
.handsontable .htDimmed > input[type=checkbox] {
  opacity: 0.6;
}
.handsontable .changeType {
  position: relative;
  z-index: 1;
}
.autocompleteEditor > .ht_master td.listbox {
  overflow: visible;
  white-space: nowrap;
}
.htUIMultipleSelectHot {
  display: grid;
}
.htColumnHeaders > .ht_master > .wtHolder {
  overflow-x: hidden !important;
  width: auto !important;
}
.htColumnHeaders {
  overflow: visible !important;
}
.htDropdownMenu > .ht_master > .wtHolder,
.htContextMenu > .ht_master > .wtHolder {
  overflow: visible !important;
}
.k-treeview-item {
  font-size: 16px;
}
.k-treeview-leaf {
  height: 100%;
  width: 100%;
  padding: 8px 16px !important;
}
.k-treeview-toggle .k-i-expand,
.k-treeview-toggle .k-i-collapse {
  cursor: default !important;
}
.k-treeview-leaf.k-selected {
  background-color: #587aff !important;
}
.k-button-solid-base.k-selected {
  background-color: #587aff !important;
  border-color: #587aff !important;
}
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
  background-color: #587aff !important;
}
.k-dropdownlist.inactive > .k-input-inner {
  text-decoration: line-through;
}
.k-tabstrip-items-wrapper .k-item {
  color: #424242 !important;
}
.k-tabstrip-items-wrapper .k-active {
  color: #587aff !important;
}
.k-tabstrip .k-content {
  z-index: unset !important;
}
.add-remove-buttons {
  text-align: left !important;
}
.add-remove-buttons div button {
  width: 30px;
}
.label-container {
  display: flex;
  min-width: 500px;
}
.label-container .label {
  user-select: none;
}
.req-form .label-container {
  min-width: unset;
}
.req-form .label-comment-container {
  width: 350px;
  user-select: none;
}
.req-form .label-container {
  width: 400px;
}
.label {
  border-color: rgba(0, 0, 0, 0.08);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #424242;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  flex: 1;
  margin-right: 0.5em;
}
.comment-label {
  float: left;
}
.label-container .comment-label {
  width: 50%;
  height: 30px !important;
}
.label-container .k-upload,
.label-container>div {
  flex: 1;
}
.label-comment-container .k-textarea {
  top: -1px;
}
.label-container>div>input {
  height: 100%;
}
.input-locked {
  opacity: 0.6;
}
.k-animation-container {
  z-index: 200 !important;
}
.req-form {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  margin-top: 2em;
}
.req-form>div {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.new-submit {
  text-align: left !important;
  margin-top: 2em;
}
.review-button.k-button {
	border-radius: 16px;
  border-width: 2px;
	width: 5em;
  height: 1.5em;
  margin: 4px 0;
  padding: 0;
	color: white;
}
.reject-button.k-button {
	border-color: #ff5050;
	background-color: #c91717;
}
.reject-button.k-button:hover {
  background-color: #ff5050;
  border-color: #c91717;
}
.approve-button.k-button {
	border-color: #7d97ff;
	background-color: #587aff;
}
.approve-button.k-button:hover {
  background-color: #7d97ff;
  border-color: #587aff;
}
.delete-button.k-button {
  width: 20px;
  height: 20px;
  padding: 0;
  margin-left: -3px;
  margin-top: -3px;
  text-align: left;
  font-size: 16px;
}
.delete-button.k-button .k-button-text {
  margin-top: -3px;
}
.duplicate-button.k-button {
  height: 20px;
  width: 26px;
  margin-top: -4px;
}
.duplicate-button-active.k-button {
	color: white;
  border-color: #7d97ff;
	background-color: #587aff;
}
.duplicate-button-active.k-button:hover {
  background-color: #7d97ff;
  border-color: #587aff;
}
.duplicate-button-active.k-button:disabled {
	color: white;
  border-color: #abbbff;
	background-color: #7f99ff;
}
.note {
  color: #7c7c7c;
  font-size: 12px;
  margin-bottom: 0;
}
.warning {
  margin-top: 8px;
}
.warning > span {
  border: 2px solid #ff5050;
  border-radius: 8px;
  padding: 8px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(8px);
  z-index: 200;
}
.modal-card {
  position: relative;
  display: inline-block;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  padding: 3.5em 1em 1em 1em;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.modal-close {
  position: relative;
  width: 2em;
  height: 2em;
  float: right;
  margin-top: -2.75em;
  margin-left: -2em;
  border-radius: 2em !important;
}
.save-card {
  width: 600px;
}
.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: "Roboto", sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
}